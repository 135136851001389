/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import axios from 'axios';
import React from 'react';

import { ApplicationSettings } from '../../shared/directus';
import { getCookie, setCookie } from '../../shared/helpers';
import { S3Roles } from '../../shared/interfaces';

interface ApplicationSettingsContextProps {
  applicationSettings: ApplicationSettings;
  token: string;
  login: (email: string, password: string) => Promise<boolean>;
  isAdmin: boolean
  cartoOnly: boolean
  isPresentationMode: boolean
  setPresentationMode: (mode: boolean) => void;
  refreshUserSettings: () => void;
  nom: string;
  prenom: string;
  s3Roles: Array<S3Roles>
}



const ApplicationSettingsContext: React.Context<ApplicationSettingsContextProps> =
  React.createContext<ApplicationSettingsContextProps>(null);

const ApplicationSettingsContextProvider: React.FC = props => {
  const [applicationSettings, setApplicationSettings] = React.useState<ApplicationSettings>(undefined);
  const [token, setToken] = React.useState<string>(undefined);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [cartoOnly, setCartoOnly] = React.useState<boolean>(false);
  const [isPresentationMode, setPresentationMode] = React.useState<boolean>(false);
  const [nom, setNom] = React.useState<string>("");
  const [prenom, setPrenom] = React.useState<string>("");
  const [s3Roles, setS3Roles] = React.useState<Array<S3Roles>>([]);

  async function loadSettings(token: string) {
    // console.log("Loading application settings...");
    const { data: loadedConfig } = await axios.get<ApplicationSettings>(`/api/application/settings`, {
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setApplicationSettings(loadedConfig);
  }

  React.useEffect(() => {
    setToken(getCookie('lynx'));
    setIsAdmin(getCookie('lynx-mode') === "true");
  }, [token]);

  React.useEffect(() => {
    loadSettings(token);
  }, [token]);

  const login = async (email: string, password: string) => {
    if (process.env.USE_SSO === "true") {
      return false;
    }
    try {
      const { data: response } = await axios.post<{token: string, isAdmin: boolean, cartoOnly: boolean}>(`/api/login`, {
        email: email,
        password: password
      });

      setCookie('lynx',response.token, 24);
      setCookie('lynx-mode',response.isAdmin, 24);
      setCookie('lynx-carto',response.cartoOnly, 24);
      setToken(response.token);
      setIsAdmin(response.isAdmin);
      setCartoOnly(response.cartoOnly);
      return true;
    } catch {
      return false;
    }
  }

  const refreshUserSettings = async () => {
    if (process.env.USE_SSO === "true") {
      const { data: response } = await axios.get<{nom: string, prenom: string, isAdmin: boolean, cartoOnly: boolean}>(`/api/v1/auth/user`);
      setIsAdmin(response.isAdmin);
      setCartoOnly(response.cartoOnly);
      setNom(response.nom);
      setPrenom(response.prenom);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setS3Roles((response as any).s3Roles);
    }
  }

  return (
    <ApplicationSettingsContext.Provider
      value={{
        applicationSettings,
        token,
        login,
        isAdmin,
        cartoOnly,
        isPresentationMode,
        setPresentationMode,
        refreshUserSettings,
        prenom,
        nom,
        s3Roles
      }}
      {...props}
    >
      {props.children}
    </ApplicationSettingsContext.Provider>
  );
};

const useApplicationSettingsContext = (): ApplicationSettingsContextProps => {
  const context = React.useContext(ApplicationSettingsContext);
  if (context === undefined) {
    throw new Error(
      `useApplicationSettingsContext must be used within a ApplicationSettingsContextProvider`,
    );
  }
  return context;
};

export { ApplicationSettingsContextProvider, useApplicationSettingsContext };
