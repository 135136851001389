/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import 'mapbox-gl/dist/mapbox-gl.css';

import { Icon } from '@blueprintjs/core';
import axios from 'axios';
import React from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import ZoomToFit from 'svg-react-loader?name=Icon!../assets/img/icons/fullscreen.svg';

import { CartoDataSet, Selectable } from '../../server/controller/business-logic';
import { getCookie } from '../../shared/helpers';
import { ActifTypesCarto, Asset, LynxDocument } from '../../shared/interfaces';
import AddressSuggest from '../components/address-suggest';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import { useDirectusContext } from '../contexts/directus-context';
import Input from './carto/input';
import KPI from './carto/kpi';
import PropertyInspector from './carto/property-inspector';
import { Section } from './carto/section-accordion';
import Select from './carto/select';
import Documents from './documents';
import MapComponent from './map-component';

enum FilterTypes {
  building = "Bâtimentaire",
  customer = "Gestionnaires & clients"
}

interface AreaRange {
  label: string;
  min: number;
  max: number;
}

const surfaceRanges: Array<AreaRange> = [
  {
    label: "0 - 1000m²",
    min: 0,
    max: 1000
  }, {
    label: "1000 - 2500m²",
    min: 1000,
    max: 2500
  }, {
    label: "2500 - 4000m²",
    min: 2500,
    max: 4000
  }, {
    label: "4000 - 10000m²",
    min: 4000,
    max: 10000
  }, {
    label: "10000 - 20000m²",
    min: 10000,
    max: 20000
  }, {
    label: "> 20000m²",
    min: 20000,
    max: 1000000000000
  }
]

enum ColorTypes {
  enterprise = "Entreprise VF IDF T",
  direction = "Direction de périmètre"
}

const Carto: React.FC = () => {
  const { /*applicationSettings, loadTables, database,*/ getColors } = useDirectusContext();
  const {isPresentationMode, refreshUserSettings} = useApplicationSettingsContext();

  const [displayFilters, setDisplayFilters] = React.useState<boolean>(true);
  const [displayPropertyInspector, setDisplayPropertyInspector] = React.useState<boolean>(false);

  const [displayMobileFilters, setDisplayMobileFilters] = React.useState<boolean>(false);
  const [displayMobilePropertyInspector, setDisplayMobilePropertyInspector] = React.useState<boolean>(false);
  const [displayMobileLegend, setDisplayMobileLegend] = React.useState<boolean>(false);

  const [displayKPIs, setDisplayKPIs] = React.useState<boolean>(true);
  const [displayDocuments, setDisplayDocuments] = React.useState<boolean>(false);

  const [filterType, setFilterType] = React.useState<FilterTypes>(FilterTypes.building);
  const [colorType, setcolorType] = React.useState<ColorTypes>(ColorTypes.enterprise);
  const [selectedVFEnterprises, setSelectedVFEnterprises] = React.useState<Array<Option>>([]);
  const [selectedVFDirections, setSelectedVFDirections] = React.useState<Array<Option>>([]);
  const [selectedActifType, setSelectedActifType] = React.useState<Array<Option>>([]);
  const [selectedAreaSlice, setSelecteAreaSlice] = React.useState<Array<Option>>([]);
  const [selectedContractType, setSelectedContractType] = React.useState<Array<Option>>([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState<Array<Option>>([]);
  const [selectedManagerType, setSelectedManagerType] = React.useState<Array<Option>>([]);
  const [selectedClientType, setSelectedClientType] = React.useState<Array<Option>>([]);
  const [cityFilter, setCityFilter] = React.useState<string>("");
  const [postalCodeFilter, setPostalCodeFilter] = React.useState<string>("");
  const [selectedClient, setSelectedClient] = React.useState<Array<Option>>([]);
  const [selectedOwner, setSelectedOwner] = React.useState<Array<Option>>([]);
  const [selectedAssetManager, setSelectedAssetManager] = React.useState<Array<Option>>([]);
  const [selectedPropertyManager, setSelectedPropertyManager] = React.useState<Array<Option>>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<Array<Option>>([]);
  const [selectedPacom, setSelectedPacom] = React.useState<Array<Option>>([]);
  const [selectedAsset, setSelectedAsset] = React.useState<string>(undefined);
  const [actifTypesOnMap, setActifTypesOnMap] = React.useState<Array<Option>>([
    {
      label: ActifTypesCarto.Gagne,
      value: ActifTypesCarto.Gagne,
    }, {
      label: ActifTypesCarto.HorsVinciEnProspection,
      value: ActifTypesCarto.HorsVinciEnProspection,
    }
  ]); 
  const [documents, setDocuments] = React.useState<Array<LynxDocument>>([]);
  const [importing, setImporting] = React.useState<boolean>(false);

  const loadDocuments = async () => {
    setImporting(true);
    const { data: loadedData } = await axios.get<Array<LynxDocument>>(`/api/documents/${selectedAsset}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setDocuments(loadedData);
    setImporting(false);
  }

  React.useEffect(() => {
    loadDocuments();
  }, [selectedAsset]);

  const { token } = useApplicationSettingsContext();
  
  const [dataSet, setDataSet] = React.useState<CartoDataSet>({
    allActifs:[],
    actifTypes:[],
    contractTypes:[],
    departments:[],
    managerTypes:[],
    clientTypes:[],
    clients:[],
    owners:[],
    assetManagers:[],
    propertyManagers:[],
    status:[],
    entreprisesVF: [],
    poles: []
  });
  const loadDataSet = async () => {
    const { data: loadedData } = await axios.get<CartoDataSet>(`/api/business/carto`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setDataSet(loadedData);
  };

  React.useEffect(() => {
    loadDataSet()
  }, [])

  React.useEffect(() => {
    refreshUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [viewport, setViewport] = React.useState({
    longitude: 2.3368161614,
    latitude: 48.8636451931,
    zoom: 12,
    width: '100vw',
    height: '100vh',
  });

  const actifsVisible:Array<Asset> = React.useMemo(() => {
    let visible = dataSet.allActifs;
    if (actifTypesOnMap.length > 0)
    {
      const selectedStatuses = actifTypesOnMap.map(item => item.value);
      visible = visible.filter(candidate => selectedStatuses.includes(candidate.StatutDeLActif))
    }
    if (selectedActifType && selectedActifType.length > 0)
    {
      visible = visible.filter(candidate => selectedActifType.find(item => item.value === candidate.typeDActif));
    }
    if (selectedVFEnterprises && selectedVFEnterprises.length > 0)
    {
      visible = visible.filter(candidate => selectedVFEnterprises.find(item => item.value === candidate.entrepriseVF));
    }
    if (selectedVFDirections && selectedVFDirections.length > 0)
    {
      visible = visible.filter(candidate => selectedVFDirections.find(item => item.value === candidate.pole));
    }
    if (selectedAreaSlice && selectedAreaSlice.length > 0)
    {
      const slices = surfaceRanges.filter(range => selectedAreaSlice.find(item => item.value === range.label));// range.label === selectedAreaSlice)
      let matching = [];
      slices.forEach(slice => {
        matching = [...matching, ...visible.filter(candidate => candidate.surfaceTotale && parseInt(candidate.surfaceTotale) >= slice.min && parseInt(candidate.surfaceTotale) < slice.max)];
      })
      matching = [... new Set(matching)];
      visible = matching;
    }
    if (selectedContractType && selectedContractType.length > 0)
    {
      visible = visible.filter(candidate => selectedContractType.find(item => item.value === candidate.typeDePrestation));//candidate.typeDePrestation === selectedContractType);
    }
    if (selectedDepartment && selectedDepartment.length > 0)
    {
      const depts = selectedDepartment.map(item => item.value).join(" ");
      visible = visible.filter(candidate => candidate.postCode && depts.includes(candidate.postCode.slice(0,2)));
      /*selectedDepartment.forEach(dept => {
        visible = visible.filter(candidate => candidate.postCode && candidate.postCode.startsWith(dept.value));
      })*/
    }
    if (cityFilter && cityFilter.trim().length > 0)
    {
      visible = visible.filter(candidate => candidate.city && candidate.city.toLocaleLowerCase().indexOf(cityFilter.trim().toLocaleLowerCase()) !== -1);
    }
    if (postalCodeFilter && postalCodeFilter.trim().length > 0)
    {
      visible = visible.filter(candidate => candidate.postCode && candidate.postCode.toLocaleLowerCase().indexOf(postalCodeFilter.trim().toLocaleLowerCase()) !== -1);
    }
    if (selectedManagerType && selectedManagerType.length > 0)
    {
      visible = visible.filter(candidate => selectedManagerType.find(item => item.value === candidate.typeGestionnaireActif));// andidate.typeGestionnaireActif === selectedManagerType);
    }
    if (selectedClientType && selectedClientType.length > 0)
    {
      visible = visible.filter(candidate => selectedClientType.find(item => item.value === candidate.typeDeClient));// acandidate.typeDeClient === selectedClientType);
    }
    if (selectedClient && selectedClient.length > 0)
    {
      visible = visible.filter(candidate => selectedClient.find(item => item.value === candidate.BDDataClient1));// candidate.BDDataClient1 === selectedClient);
    }
    if (selectedOwner && selectedOwner.length > 0)
    {
      visible = visible.filter(candidate => selectedOwner.find(item => item.value === candidate.BDDataProprio));// candidate.BDDataProprio === selectedOwner);
    }
    if (selectedAssetManager && selectedAssetManager.length > 0)
    {
      visible = visible.filter(candidate => selectedAssetManager.find(item => item.value === candidate.BDDataAssetMgr));// candidate.RelActifsComplerVersAssetManager === selectedAssetManager);
    }
    if (selectedPropertyManager && selectedPropertyManager.length > 0)
    {
      visible = visible.filter(candidate => selectedPropertyManager.find(item => item.value === candidate.BDDataPM)); // candidate.BDDataPM === selectedPropertyManager);
    }
    if (selectedStatus && selectedStatus.length > 0)
    {
      visible = visible.filter(candidate => selectedStatus.find(item => item.value === candidate.StatutDeLActif)); // candidate.StatutDeLActif === selectedStatus);
    }
    if (selectedPacom && selectedPacom.length > 0)
    {
      visible = visible.filter(candidate => selectedPacom.find(item => item.value === candidate.pacom)); // selectedPacom.find(item => item.candidate.pacom === selectedPacom);
    }

    return visible;
  }, [dataSet.allActifs, actifTypesOnMap, selectedActifType, selectedVFEnterprises, selectedVFDirections, selectedAreaSlice, selectedContractType, selectedDepartment, cityFilter, postalCodeFilter, selectedManagerType, selectedClientType, selectedClient, selectedOwner, selectedAssetManager, selectedPropertyManager, selectedStatus, selectedPacom]);

  const groupes: Record<string, string> = React.useMemo(() => {
    const result = {};
    actifsVisible.forEach(actif => {
      if (colorType === ColorTypes.enterprise)
      {
        result[actif.uuid] = actif.entrepriseVF
      } else {
        result[actif.uuid] = actif.pole
      }
    })
    return result;
  }, [actifsVisible, colorType]);

  const areaSlices: Array<Selectable> = React.useMemo(() => {
    return surfaceRanges.map(item => {
      return {
        label: item.label,
        value: item.label
      }
    });
  }, []);

  const pacoms: Array<Selectable> = React.useMemo(() => {
    if (!dataSet) {
      return [];
    }
    const allPacoms = [... new Set(dataSet.allActifs.filter(a => a.pacom).map(a => a.pacom))].sort().map(item => {
      return {
        label: item,
        value: item
      }
    });
    return [{
      label: "Tous",
      value: "Tous"
    }, ...allPacoms]
  }, [dataSet]);

  const siteNumber: string = React.useMemo(() => {
    return actifsVisible.length.toFixed(0);
  }, [actifsVisible]);

  const vfCount: string = React.useMemo(() => {
    const entreprises = actifsVisible.filter(a => a.entrepriseVF).map(a => a.entrepriseVF);
    return `${[... new Set(entreprises)].length}`;
  }, [actifsVisible]);

  const totalArea: string = React.useMemo(() => {
    let surface = 0;
    actifsVisible.filter(a => a.surfaceTotale).forEach(a => surface += parseInt(a.surfaceTotale));
    const unit = 'm²';
    
    return `${Math.ceil(surface).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${unit}`;
  }, [actifsVisible]);

  const clientNumber: string = React.useMemo(() => {
    const entreprises = actifsVisible.filter(a => a.BDDataClient1).map(a => a.BDDataClient1);
    return `${[... new Set(entreprises)].length}`;
  }, [actifsVisible]);

  const editedAsset = React.useMemo(() => {
    return dataSet.allActifs.find(a => a.uuid === selectedAsset)
  }, [dataSet, selectedAsset]);

  const sections: Array<Section> = React.useMemo(() => {
    if (!editedAsset || !editedAsset.propertyInspector)
    {
      return [];
    }
    const convertedSections: Array<Section> = []
    Object.keys(editedAsset.propertyInspector).forEach(sectionKey => {
      const section: Section = {
        titre: sectionKey,
        properties: []
      }
      Object.keys(editedAsset.propertyInspector[sectionKey]).forEach(propKey => {
        section.properties.push({
          key: propKey,
          value: editedAsset.propertyInspector[sectionKey][propKey]
        })
      })
      convertedSections.push(section);
    });
    return convertedSections;
  }, [editedAsset]);

  const colors: Record<string, string> = React.useMemo(() => {
    const result = {}
    const values = Object.values(groupes);
    const config: Record<string, string> = getColors;
    [...new Set(values)].sort().forEach(group => {
      const randomColor = Math.floor(Math.random()*16777215).toString(16);
      result[group] = config[group]??`#${randomColor}`;
      config[group] = result[group];
    });
    return result;
  }, [groupes, getColors]);

  const actifTypesCartoOptions = React.useMemo(() => {
    return Object.values(ActifTypesCarto).map(entry => { return {
      label: entry,
      value: entry
    }})
  }, []);

  React.useEffect(() => {
    if (isPresentationMode) {
      setFilterType(FilterTypes.building);
      
      setSelectedActifType([]);
      setSelectedVFEnterprises([]);
      setSelectedVFDirections([]);
      setSelecteAreaSlice([]);
      setSelectedContractType([]);
      setSelectedDepartment([]);
      setSelectedManagerType([]);
      setSelectedClientType([]);
      setCityFilter("");
      setPostalCodeFilter("");
      setSelectedClient([]);
      setSelectedOwner([]);
      setSelectedAssetManager([]);
      setSelectedPropertyManager([]);
      setSelectedStatus([]);
      setSelectedPacom([]);
      setSelectedAsset(undefined);
      setActifTypesOnMap([
        {
          label: ActifTypesCarto.Gagne,
          value: ActifTypesCarto.Gagne,
        }
      ]); 
    }
  }, [isPresentationMode]);

  React.useEffect(() => {
    setSelectedVFEnterprises([]);
    setSelectedVFDirections([]);
  }, [colorType])

  const presentationColors = React.useMemo(() => {
    const result = JSON.parse(JSON.stringify(colors));
    Object.keys(result).forEach(key => result[key] = "#ff0000")
    return result;
  }, [colors])

  /*const presentationGroupes = React.useMemo(() => {
    const result = JSON.parse(JSON.stringify(groupes));
    Object.keys(result).forEach(key => result[key] = "#ff0000")
    return result;
  }, [groupes])*/


  return (
    <>
      <div className="w-full h-full hidden md:block">
        <div className="w-full h-full flex flex-row p-2 items-center overflow-hidden relative">
          {
            <div className='w-full h-full absolute top-0 left-0 z-0' >
              <MapComponent 
                viewport={viewport} 
                setViewport={setViewport} 
                assets={actifsVisible}
                colors={isPresentationMode ? presentationColors: colors} 
                groupes={groupes} 
                onSelect={(uuid) => {
                  setSelectedAsset(uuid)
                  setDisplayPropertyInspector(true)
                }}
              />
            </div>
          }
          {
            displayFilters &&
            <div className='w-1/5 h-full p-4 shadow-xl bg-white flex flex-col gap-2 overflow-hidden z-50 border-r-4'>
              {
                <div className='w-full overflow-ellipsis whitespace-nowrap bg-lynx-vinci text-white p-4 font-bold text-xl flex flex-row items-center'>
                  <div className='flex-1'>
                    Filtres
                  </div>
                  <Icon color="white" icon="cross" className='cursor-pointer' onClick={() => {
                    setDisplayFilters(false)
                  }}/>
                </div>
                
              }
              <AddressSuggest lat={viewport.latitude} lon={viewport.longitude} onMove={
                (lon: number, lat: number) => {
                  setViewport({
                    ...viewport,
                    longitude: lon,
                    latitude: lat,
                    zoom: 20,
                    width: '100vw',
                    height: '100vh',
                  });
                }
              }/>
              <div className='w-full flex-1 overflow-hidden'>
                <div className='w-full h-full flex flex-col gap-2 overflow-auto pr-2'>
                  <div className='w-full flex flex-row items-center justify-center '>
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.building &&
                      <>
                        <button className='rounded-l-lg border-2 border-lynx-background bg-white'>
                          <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                        </button>
                        <button className='rounded-r-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                          setFilterType(FilterTypes.customer)
                        }}>
                          <img className="opacity-60 p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                        </button>
                      </>
                    }
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.customer &&
                      <>
                        <button className='rounded-l-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                          setFilterType(FilterTypes.building)
                        }}>
                          <img className="opacity-60 p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                        </button>
                        <button className='rounded-r-lg border-2 border-lynx-background bg-white' >
                          <img className="p-2  block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                        </button>
                      </>
                    }
                    </div>
                    <div className='w-full flex flex-row items-center gap-2'>
                      {
                        filterType === FilterTypes.building &&
                        <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                      }
                      {
                        filterType === FilterTypes.customer &&
                        <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                      }
                      <div className='font-bold text-lynx-vinci text-xl'>
                        {filterType}
                      </div>
                    </div>
                    {
                      filterType === FilterTypes.building &&
                      <>
                        {
                          !isPresentationMode &&
                          <>
                          {
                            colorType === ColorTypes.enterprise &&
                            <Select 
                              label="Entreprises VF" 
                              values={dataSet.entreprisesVF} 
                              seletected={selectedVFEnterprises}
                              onSelected={setSelectedVFEnterprises}
                            />
                          }
                          {
                            colorType === ColorTypes.direction &&
                            <Select 
                              label="Directions VF" 
                              values={dataSet.poles} 
                              seletected={selectedVFDirections}
                              onSelected={setSelectedVFDirections}
                            />
                          }
                          </>
                        }
                        <Select 
                          label="Type d'actif" 
                          values={dataSet.actifTypes} 
                          seletected={selectedActifType}
                          onSelected={setSelectedActifType}
                        />
                        <Select 
                          label="Tranche de surface" 
                          values={areaSlices} 
                          seletected={selectedAreaSlice}
                          onSelected={setSelecteAreaSlice}
                        />
                        <Select 
                          label="Type de contrat" 
                          values={dataSet.contractTypes} 
                          seletected={selectedContractType}
                          onSelected={setSelectedContractType}
                        />
                        <div className='w-full min-h-4 h-4 border-b-2 border-lynx-background'></div>
                        <Select 
                          label="Département" 
                          values={dataSet.departments} 
                          seletected={selectedDepartment}
                          onSelected={setSelectedDepartment}
                        />
                        <Input 
                          label="Ville" 
                          placeHolder={"ex: Paris..."} 
                          seletected={cityFilter}
                          onSelected={setCityFilter}
                        />
                        <Input 
                          label="Code postal" 
                          placeHolder={"ex: 75020..."} 
                          seletected={postalCodeFilter}
                          onSelected={setPostalCodeFilter}
                        />
                      </>
                    }
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.customer &&
                      <>
                        <Select 
                          label="Type de gestionnaire d'actif" 
                          values={dataSet.managerTypes} 
                          seletected={selectedManagerType}
                          onSelected={setSelectedManagerType}
                        />
                        <Select 
                          label="Type de client" 
                          values={dataSet.clientTypes} 
                          seletected={selectedClientType}
                          onSelected={setSelectedClientType}
                        />
                        <Select 
                          label="Client" 
                          values={dataSet.clients} 
                          seletected={selectedClient}
                          onSelected={setSelectedClient}
                        />
                        <Select 
                          label="Propriétaire" 
                          values={dataSet.owners} 
                          seletected={selectedOwner}
                          onSelected={setSelectedOwner}
                        />
                        <Select 
                          label="Foncière privée / Asset Manager" 
                          values={dataSet.assetManagers} 
                          seletected={selectedAssetManager}
                          onSelected={setSelectedAssetManager}
                        />
                        <Select 
                          label="Property Manager" 
                          values={dataSet.propertyManagers} 
                          seletected={selectedPropertyManager}
                          onSelected={setSelectedPropertyManager}
                        />
                        <div className='w-full min-h-4 h-4 border-b-2 border-lynx-background'></div>
                        <Select 
                          label="Porteur de l'action commerciale" 
                          values={pacoms} 
                          seletected={selectedPacom}
                          onSelected={setSelectedPacom}
                        />
                        <Select 
                          label="Statut de l'actif" 
                          values={dataSet.status} 
                          seletected={selectedStatus}
                          onSelected={setSelectedStatus}
                        />
                      </>
                    }
                  </div>
                </div>
              
            </div>
          }
          {
            !displayFilters &&
            <div className='w-12 h-full bg-white text-black hover:text-blue-700 flex flex-col items-center z-50 border-r-4'>
              <Icon  icon="filter" className='cursor-pointer pt-2' onClick={() => {
                setDisplayFilters(true)
              }}/>
            </div>
          }
          <div className='flex-1 h-full z-50 pointer-events-none'>
            <div className='w-full h-full overflow-hidden flex flex-col pointer-events-none' style={{ position: "relative"}}>
              {
                displayDocuments &&
                <div className='absolute top-0 w-full h-full bg-white flex flex-row items-center border-b-4 border-lynx-background overflow-hidden' style={{zIndex: 10000}}>
                  <Documents assetId={selectedAsset} asset={editedAsset} onClose={() => { setDisplayDocuments(false)}} documents={documents} loadDocuments={loadDocuments} importing={importing} setImporting={setImporting}/>
                </div>
              }
              {
                displayKPIs &&
                <div className='w-full flex flex-row items-center border-b-4 border-lynx-background'>
                  <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                    <KPI label='Nombre de sites' value={siteNumber}/>
                  </div>
                  
                  {
                    !isPresentationMode &&
                    <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                      <KPI label={`Nombre d'entreprises VF`} value={vfCount}/>
                    </div>
                  }
                  
                  <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                    <KPI label='Surface totale (m2)' value={totalArea}/>
                  </div>
                  
                  <div className='flex-1 h-full overflow-hidden'>
                    <KPI label='Nombre de clients' value={clientNumber}/>
                  </div>
                </div>
              }
              {
                !isPresentationMode &&
                <div className='w-full flex-1 relative pointer-events-none'>
                  <MultiSelect
                      options={actifTypesCartoOptions}
                      className='w-1/4 min-w-1/4 text-sm absolute top-0 right-0 p-2 pointer-events-auto'
                      value={actifTypesOnMap}
                      onChange={setActifTypesOnMap}
                      labelledBy={"actif carto"}
                      overrideStrings={{
                        "allItemsAreSelected": "Tous...",
                        "clearSearch": "Nettoyer la recherche",
                        "clearSelected": "Déselectionner",
                        "noOptions": "Pas d'otions correspondante",
                        "search": "Filtrer..",
                        "selectAll": "Sélectionner tout",
                        "selectAllFiltered": "Sélectionner",
                        "selectSomeItems": "Tous...",
                        "create": "Create",
                      }}
                    />
                </div>
              }
              <div className='w-full flex flex-row items-center p-2 pointer-events-none pb-2'>
                {
                  !isPresentationMode &&
                  colorType === ColorTypes.enterprise &&
                  <>
                    <button className='pointer-events-auto font-bold text-lg overflow-ellipsis whitespace-nowrap outline-none flex items-center p-2 h-12 rounded-l-lg border-2 border-lynx-background bg-white'>
                      Entreprise VF IDF T
                    </button>
                    <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap outline-none flex items-center p-2 h-12 rounded-r-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                      setcolorType(ColorTypes.direction)
                    }}>
                      Direction de périmètre
                    </button>
                  </>
                }
                {
                  !isPresentationMode &&
                  colorType === ColorTypes.direction &&
                  <>
                    <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap  outline-none flex items-center p-2 h-12 rounded-l-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                      setcolorType(ColorTypes.enterprise)
                    }}>
                      Entreprise VF IDF T
                    </button>
                    <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap  font-bold outline-none flex items-center p-2 h-12 rounded-r-lg border-2 border-lynx-background bg-white' >
                      Direction de périmètre
                    </button>
                  </>
                }
                <div className='flex-1 flex item-center justify-end'>
                  <button className={`${displayKPIs ? "bg-white border-white" : "bg-blue-100 border-yellow-500"} w-12 rounded-2xl pointer-events-auto border-2`} onClick={() => {
                    if (displayKPIs) {
                      setDisplayFilters(false)
                      setDisplayKPIs(false)
                      setDisplayPropertyInspector(false)
                    } else {
                      setDisplayFilters(true)
                      setDisplayKPIs(true)
                    }
                    }}>
                    <ZoomToFit className='w-full h-full'/>
                  </button>
                </div>
              </div>

              {
                !isPresentationMode &&
                <div className='w-full max-h-16 h-16 overflow-auto bg-white p-2 pointer-events-auto'>
                  <div className='w-full bg-white flex flex-row gap-2 items-center flex-wrap'>
                    {
                      Object.keys(colors).map(legend => {
                        return (
                          <div key={`${legend}`} className='flex flex-row gap-2 items-center'>
                            <div className='w-8 h-8 rounded border-gray-400 border-2' style={{backgroundColor: colors[legend]}}></div>
                            <div className='whitespace-nowrap overflow-ellipsis' >{legend}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          {
            !isPresentationMode &&
            displayPropertyInspector &&
            <div className='w-1/5 h-full bg-white flex flex-col items-center z-50 bg-lynx-background border-l-4'>
              <PropertyInspector documents={documents} status={editedAsset ? editedAsset.StatutDeLActif : "N/A"} uuid={editedAsset? editedAsset.uuid : undefined} siteName={editedAsset ? editedAsset.BDDataNomComSite === "N/A" ? editedAsset.adress : editedAsset.BDDataNomComSite : "Nom commercial du site"} sections={sections} onClose={() => {
                setDisplayPropertyInspector(false);
              }}
                setDisplayDocuments={() => { setDisplayDocuments(!displayDocuments) }}
              />
            </div>
          }
          {
            !isPresentationMode &&
            !displayPropertyInspector &&
            <div className='w-12 h-full bg-white flex flex-col items-center z-50 border-l-4'>
              <img onClick={() => { setDisplayPropertyInspector(!displayPropertyInspector)}} className="cursor-pointer p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/propertyinspector.svg`} />
            </div>
          }
        </div>
      </div>
      
      <div className="w-full h-full md:hidden">
        <div className="w-full h-full flex flex-col items-center overflow-hidden relative">
          {
            <div className='w-full h-full absolute top-0 left-0 z-0' >
              <MapComponent 
                viewport={viewport} 
                setViewport={setViewport} 
                assets={actifsVisible}
                colors={isPresentationMode ? presentationColors: colors} 
                groupes={groupes} 
                onSelect={(uuid) => {
                  setSelectedAsset(uuid)
                  setDisplayPropertyInspector(true)
                }}
              />
            </div>
          }
          {
            displayMobileFilters &&
            <div className='w-full h-full bg-white flex flex-col gap-2 overflow-hidden z-50 pl-2 pr-2'>
              {
                <div className='w-full overflow-ellipsis whitespace-nowrap bg-lynx-vinci text-white p-4 font-bold text-xl flex flex-row items-center'>
                  <div className='flex-1'>
                    Filtres
                  </div>
                  <Icon color="white" icon="cross" className='cursor-pointer' onClick={() => {
                    setDisplayMobileFilters(false)
                  }}/>
                </div>
                
              }
              <AddressSuggest lat={viewport.latitude} lon={viewport.longitude} onMove={
                (lon: number, lat: number) => {
                  setViewport({
                    ...viewport,
                    longitude: lon,
                    latitude: lat,
                    zoom: 20,
                    width: '100vw',
                    height: '100vh',
                  });
                }
              }/>
              {
                !isPresentationMode &&
                <MultiSelect
                  options={actifTypesCartoOptions}
                  className='w-full text-sm pointer-events-auto'
                  value={actifTypesOnMap}
                  onChange={setActifTypesOnMap}
                  labelledBy={"actif carto"}
                  overrideStrings={{
                    "allItemsAreSelected": "Tous...",
                    "clearSearch": "Nettoyer la recherche",
                    "clearSelected": "Déselectionner",
                    "noOptions": "Pas d'otions correspondante",
                    "search": "Filtrer..",
                    "selectAll": "Sélectionner tout",
                    "selectAllFiltered": "Sélectionner",
                    "selectSomeItems": "Tous...",
                    "create": "Create",
                  }}
                />
              }
              <div className='w-full flex-1 overflow-hidden'>
                <div className='w-full h-full flex flex-col gap-2 overflow-auto pr-2'>
                  <div className='w-full flex flex-row items-center justify-center pb-4'>
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.building &&
                      <>
                        <button className='rounded-l-lg border-2 border-lynx-background bg-white'>
                          <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                        </button>
                        <button className='rounded-r-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                          setFilterType(FilterTypes.customer)
                        }}>
                          <img className="opacity-60 p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                        </button>
                      </>
                    }
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.customer &&
                      <>
                        <button className='rounded-l-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                          setFilterType(FilterTypes.building)
                        }}>
                          <img className="opacity-60 p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                        </button>
                        <button className='rounded-r-lg border-2 border-lynx-background bg-white' >
                          <img className="p-2  block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                        </button>
                      </>
                    }
                    </div>
                    <div className='w-full flex flex-row items-center gap-2'>
                      {
                        filterType === FilterTypes.building &&
                        <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
                      }
                      {
                        filterType === FilterTypes.customer &&
                        <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/user.svg`} />
                      }
                      <div className='font-bold text-lynx-vinci text-xl'>
                        {filterType}
                      </div>
                    </div>
                    {
                      filterType === FilterTypes.building &&
                      <>
                        {
                          !isPresentationMode &&
                          <>
                          {
                            colorType === ColorTypes.enterprise &&
                            <Select 
                              label="Entreprises VF" 
                              values={dataSet.entreprisesVF} 
                              seletected={selectedVFEnterprises}
                              onSelected={setSelectedVFEnterprises}
                            />
                          }
                          {
                            colorType === ColorTypes.direction &&
                            <Select 
                              label="Directions VF" 
                              values={dataSet.poles} 
                              seletected={selectedVFDirections}
                              onSelected={setSelectedVFDirections}
                            />
                          }
                          </>
                        }
                        <Select 
                          label="Type d'actif" 
                          values={dataSet.actifTypes} 
                          seletected={selectedActifType}
                          onSelected={setSelectedActifType}
                        />
                        <Select 
                          label="Tranche de surface" 
                          values={areaSlices} 
                          seletected={selectedAreaSlice}
                          onSelected={setSelecteAreaSlice}
                        />
                        <Select 
                          label="Type de contrat" 
                          values={dataSet.contractTypes} 
                          seletected={selectedContractType}
                          onSelected={setSelectedContractType}
                        />
                        <div className='w-full min-h-4 h-4 border-b-2 border-lynx-background'></div>
                        <Select 
                          label="Département" 
                          values={dataSet.departments} 
                          seletected={selectedDepartment}
                          onSelected={setSelectedDepartment}
                        />
                        <Input 
                          label="Ville" 
                          placeHolder={"ex: Paris..."} 
                          seletected={cityFilter}
                          onSelected={setCityFilter}
                        />
                        <Input 
                          label="Code postal" 
                          placeHolder={"ex: 75020..."} 
                          seletected={postalCodeFilter}
                          onSelected={setPostalCodeFilter}
                        />
                      </>
                    }
                    {
                      !isPresentationMode &&
                      filterType === FilterTypes.customer &&
                      <>
                        <Select 
                          label="Type de gestionnaire d'actif" 
                          values={dataSet.managerTypes} 
                          seletected={selectedManagerType}
                          onSelected={setSelectedManagerType}
                        />
                        <Select 
                          label="Type de client" 
                          values={dataSet.clientTypes} 
                          seletected={selectedClientType}
                          onSelected={setSelectedClientType}
                        />
                        <Select 
                          label="Client" 
                          values={dataSet.clients} 
                          seletected={selectedClient}
                          onSelected={setSelectedClient}
                        />
                        <Select 
                          label="Propriétaire" 
                          values={dataSet.owners} 
                          seletected={selectedOwner}
                          onSelected={setSelectedOwner}
                        />
                        <Select 
                          label="Foncière privée / Asset Manager" 
                          values={dataSet.assetManagers} 
                          seletected={selectedAssetManager}
                          onSelected={setSelectedAssetManager}
                        />
                        <Select 
                          label="Property Manager" 
                          values={dataSet.propertyManagers} 
                          seletected={selectedPropertyManager}
                          onSelected={setSelectedPropertyManager}
                        />
                        <div className='w-full min-h-4 h-4 border-b-2 border-lynx-background'></div>
                        <Select 
                          label="Porteur de l'action commerciale" 
                          values={pacoms} 
                          seletected={selectedPacom}
                          onSelected={setSelectedPacom}
                        />
                        <Select 
                          label="Statut de l'actif" 
                          values={dataSet.status} 
                          seletected={selectedStatus}
                          onSelected={setSelectedStatus}
                        />
                      </>
                    }
                  </div>
                </div>
              
            </div>
          }
          {
            !displayMobileFilters && !displayMobilePropertyInspector &&
            <div className='w-full h-full z-50 pointer-events-none'>
              <div className='w-full h-full overflow-hidden flex flex-col pointer-events-none'>
                {
                  displayKPIs &&
                  <div className='w-full flex flex-row items-center border-b-4 border-lynx-background pt-2'>
                    <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                      <KPI label='Sites' value={siteNumber} size="text-xl"/>
                    </div>
                    
                    {
                      !isPresentationMode &&
                      <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                        <KPI label={`Entreprises`} value={vfCount} size="text-xl"/>
                      </div>
                    }
                    
                    <div className='flex-1 h-full overflow-hidden border-r-4 border-lynx-background'>
                      <KPI label='Surface' value={totalArea} size="text-xl"/>
                    </div>
                    
                    <div className='flex-1 h-full overflow-hidden'>
                      <KPI label='Clients' value={clientNumber} size="text-xl"/>
                    </div>
                  </div>
                }
                <div className='flex-1 w-full'>
                </div>
                <div className='w-full flex flex-row items-center p-2 pointer-events-none pb-2'>
                  {
                    !isPresentationMode &&
                    colorType === ColorTypes.enterprise &&
                    <>
                      <button className='pointer-events-auto font-bold text-lg overflow-ellipsis whitespace-nowrap outline-none flex items-center p-2 h-12 rounded-l-lg border-2 border-lynx-background bg-white'>
                        Entreprise VF IDF T
                      </button>
                      <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap outline-none flex items-center p-2 h-12 rounded-r-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                        setcolorType(ColorTypes.direction)
                      }}>
                        Direction de périmètre
                      </button>
                    </>
                  }
                  {
                    !isPresentationMode &&
                    colorType === ColorTypes.direction &&
                    <>
                      <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap  outline-none flex items-center p-2 h-12 rounded-l-lg border-2 border-lynx-background bg-lynx-background' onClick={() => {
                        setcolorType(ColorTypes.enterprise)
                      }}>
                        Entreprise VF IDF T
                      </button>
                      <button className='pointer-events-auto text-lg overflow-ellipsis whitespace-nowrap  font-bold outline-none flex items-center p-2 h-12 rounded-r-lg border-2 border-lynx-background bg-white' >
                        Direction de périmètre
                      </button>
                    </>
                  }
                  <div className='flex-1 flex item-center justify-end'>
                    <button className={`${displayKPIs ? "bg-white border-white" : "bg-blue-100 border-yellow-500"} w-12 rounded-2xl pointer-events-auto border-2`} onClick={() => {
                      if (displayKPIs) {
                        setDisplayFilters(false)
                        setDisplayKPIs(false)
                        setDisplayPropertyInspector(false)
                      } else {
                        setDisplayFilters(true)
                        setDisplayKPIs(true)
                      }
                      }}>
                      <ZoomToFit className='w-full h-full'/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            !isPresentationMode &&
            displayMobilePropertyInspector &&
            <div className='w-full h-full bg-white flex flex-col items-center z-50 bg-lynx-background overflow-hidden'>
              <PropertyInspector documents={documents} status={editedAsset ? editedAsset.StatutDeLActif : "N/A"} uuid={editedAsset? editedAsset.uuid : undefined} siteName={editedAsset ? editedAsset.BDDataNomComSite === "N/A" ? editedAsset.adress : editedAsset.BDDataNomComSite : "Nom commercial du site"} sections={sections} onClose={() => {
                setDisplayMobilePropertyInspector(false);
              }} setDisplayDocuments={() => {}}/>
            </div>
          }
          <div className='w-full z-50 bg-white flex flex-row gap-2 items-center overflow-hidden justify-center' >
            {/*
              !isPresentationMode &&
              !displayMobileFilters && !displayMobilePropertyInspector &&
              displayKPIs &&
              <div className='w-full max-h-16 h-16 overflow-auto bg-white p-2 pointer-events-auto'>
                <div className='w-full bg-white flex flex-row gap-2 items-center flex-wrap'>
                  {
                    Object.keys(colors).map(legend => {
                      return (
                        <div key={`${legend}`} className='flex flex-row gap-2 items-center'>
                          <div className='w-8 h-8 rounded border-gray-400 border-2' style={{backgroundColor: colors[legend]}}></div>
                          <div className='whitespace-nowrap overflow-ellipsis' >{legend}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
                */}
          </div>
          <div className='w-full absolute top-50 z-50 bg-white flex flex-row gap-2 items-center overflow-hidden justify-center' >
            {
              !isPresentationMode &&
              !displayMobileFilters && !displayMobilePropertyInspector &&
              displayMobileLegend &&
              <div className='w-full h-full overflow-auto bg-white p-2 pointer-events-auto'>
                <div className='w-full bg-white flex flex-row gap-2 items-center flex-wrap'>
                  {
                    Object.keys(colors).map(legend => {
                      return (
                        <div key={`${legend}`} className='flex flex-row gap-2 items-center'>
                          <div className='w-8 h-8 rounded border-gray-400 border-2' style={{backgroundColor: colors[legend]}}></div>
                          <div className='whitespace-nowrap overflow-ellipsis' >{legend}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }
          </div>
          <div className='w-full z-50 bg-white flex flex-row gap-2 items-center overflow-hidden justify-center' >
            <button className={"hover:opacity-90 bg-lynx-vinci text-white rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
              setDisplayMobileFilters(!displayMobileFilters);
              setDisplayMobilePropertyInspector(false);
              setDisplayMobileLegend(false);
            }}>
                {displayMobileFilters ? "Fermer les filtres" : "Filtrer les résultats"}
            </button>
            <button disabled={!selectedAsset} className={`hover:opacity-90 ${selectedAsset ? 'bg-lynx-low' : "bg-gray-500"} text-white rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2`} onClick={async () => {
              setDisplayMobilePropertyInspector(!displayMobilePropertyInspector);
              setDisplayMobileFilters(false);
              setDisplayMobileLegend(false);
            }}>
                {displayMobilePropertyInspector ? "Fermer les propriétés" : selectedAsset ? "Afficher les propriétés" : "Sélectionner un actif"}
            </button>
            
            <button className={"hover:opacity-90 bg-lynx-low text-white rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
              setDisplayMobilePropertyInspector(false);
              setDisplayMobileFilters(false);
              setDisplayMobileLegend(!displayMobileLegend);
            }}>
                {"Légende"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carto;