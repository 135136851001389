/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Icon } from '@blueprintjs/core';
import axios from 'axios';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import Carto from './carto';
import Pacom from './pacom';
import Prospection from './prospection';
import Settings from './settings';
import Taguage from './taguage';

const TabMenu: React.FC<{
  text: string
  isSelected: boolean
  onClick: () => void
  icon: string
}> = ({
  text,
  isSelected,
  onClick,
  icon
}) => {
  return (
    <div onClick={onClick} className={`hover:border-color-lynx-medium flex flex-row-gap-2 items-center cursor-pointer p-4 font-bold text-xl text-lynx-vinci border-b-4 ${isSelected ? "text-bold border-lynx-lynx" : "border-lynx-vinci opacity-60"}`}>
      <div className='h-full'>
        <img className="block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/${icon}`} />
      </div>
      <div>{text.toLocaleUpperCase()}</div>
    </div>
  )
}

const ButtonMenu: React.FC<{
  text: string
  isSelected: boolean
  onClick: () => void
  icon: string
}> = ({
  text,
  isSelected,
  onClick,
  icon
}) => {
  return (
    <div onClick={onClick} className={`w-1/4 flex flex-col gap-2 items-center justify-center cursor-pointer p-2 font-bold text-xl text-lynx-vinci border-b-4 ${isSelected ? "text-bold border-lynx-lynx" : "border-lynx-vinci opacity-60"}`}>
      <div className='w-full flex flex-row items-center justify-center'>
        <img className="object-contain block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/${icon}`} />
      </div>
      <div className='overflow-ellipsis whitespace-nowrap'>{text.toLocaleUpperCase()}</div>
    </div>
  )
}

enum Pages {
  taguage="taguage",
  prospection="prospection",
  pacom="pacom",
  carto="carto",
  settings="settings"
}

const Lynx: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = React.useState<Pages>(Pages.carto);
  const navigate = useNavigate();
  const {isAdmin, cartoOnly, isPresentationMode, setPresentationMode, nom, prenom} = useApplicationSettingsContext();
  const [textFilter, setTextFilter] = React.useState<string>("");

  const navigateTo = (target: string) => {
    if (target.startsWith("http"))
    {
      window.open(target);
    } else {
      navigate(target);
    }
  }

  React.useEffect(() => {
    if (!searchParams)
    {
      return;
    }
    if (searchParams.get("page"))
    {
      setCurrentPage(searchParams.get("page") as any)
    }
  }, [searchParams, setCurrentPage])

  return (
    <>
      <div className="w-full h-full hidden md:block">
        <div className="w-full h-full flex flex-col gap-2 items-center bg-lynx-background">
          <div className='w-full flex flex-row gap-2 p-2 items-center h-24 bg-white'>
            <img className='h-full pr-8 cursor-pointer' src="./assets/img/logos/lynx.png" onClick={() => {
              navigate("/menu")
            }}/>
            <div className='flex-1 flex flex-row'>
              {
                !isPresentationMode &&
                <>
                  {
                    !cartoOnly &&
                    <>
                      <TabMenu text="Taguage" isSelected={currentPage===Pages.taguage} icon="tag.svg" onClick={() => {setSearchParams({ page: Pages.taguage});}}/>
                      <TabMenu text="Prospection"  isSelected={currentPage===Pages.prospection}  icon="prospection.svg" onClick={() => {setSearchParams({ page: Pages.prospection});}}/>
                      <TabMenu text="PACOM" isSelected={currentPage===Pages.pacom}  icon="pacom.svg" onClick={() => {setSearchParams({ page: Pages.pacom});}}/>
                    </>
                  }
                </>
              }
              <TabMenu text="Cartographie"  isSelected={currentPage===Pages.carto}  icon="map.svg" onClick={() => {setSearchParams({ page: Pages.carto});}}/>
            </div>
            {
              !cartoOnly &&
              currentPage===Pages.taguage &&
              <button className={"hover:opacity-90 bg-lynx-info text-xl rounded h-10 font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                  navigateTo("https://safeplace.teepee.fr/#/viewData/CD986F39/34425AE7/Appariement/MiseAJourDesFormulaires?context=022383A9-DC04-4C50-96BA-4654F989D9FC");
                }}>
                  Nouvelle demande de taguage
              </button>
            }
            <div className='flex-1 flex flex-row justify-end items-center gap-4 pr-2'>
              <button className={"hover:opacity-90 bg-lynx-power-bi h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                navigateTo("https://app.powerbi.com/groups/me/reports/4cb4d041-5633-4cbd-b959-6eb5ddbf144c/20980515b093c0aa4655?ctid=cae7d061-08f3-40dd-80c3-3c0b8889224a&experience=power-bi"); 
              }}>
                Power BI
              </button>
              {
                currentPage===Pages.carto &&
                <button className={`${isPresentationMode ? "bg-blue-100" : "bg-lynx-background"} hover:bg-yellow-200  h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4`} onClick={async () => {
                  setPresentationMode(!isPresentationMode)
                  if (!isPresentationMode)
                  {
                    setSearchParams({ page: Pages.carto});
                  }
                }}>
                  <Icon className="" icon="presentation"/>
                </button>
              }
              
              {
                isAdmin && 
                <button className={"hover:bg-yellow-200 bg-lynx-background h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                  setSearchParams({ page: "settings"});
                }}>
                  <Icon className="" icon="cog"/>
                </button>
              }
              <button className={"hover:opacity-90 bg-red-500 text-white h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                axios.get("/api/v1/auth/logout")
                window.location = "/" as any
              }}>
                <Icon className="" icon="log-out"/>
              </button>
            </div>
          </div>
          <div className='w-full flex-1 overflow-hidden bg-lynx-background'>
            <div className='w-full h-full overflow-hidden flex flex-col gap-2 items-center'>
              
              {!cartoOnly && currentPage === Pages.taguage && <Taguage textFilter={textFilter} setTextFilter={setTextFilter}/>}
              {!cartoOnly && currentPage === Pages.pacom && <Pacom textFilter={textFilter} setTextFilter={setTextFilter}/>}
              {currentPage === Pages.carto && <Carto />}
              {!cartoOnly && currentPage === Pages.prospection && <Prospection textFilter={textFilter} setTextFilter={setTextFilter}/>}
              {!cartoOnly && currentPage === Pages.settings && <Settings />}
            </div>
          </div>
          <div className='w-full flex flex-row gap-2 p-2 items-center h-16 overflow-hidden bg-white pl-4 pr-4' >
            <div>
              Lynx © 2025 - Version {process.env.VERSION} - Bonne journée {prenom} {nom}
            </div>
            <div className='flex-1 h-full flex flex-row items-center justify-end overflow-hidden'>
              <div className='w-full h-full flex flex-row items-center justify-end overflow-hidden'>
                <img className="block max-w-full max-h-full w-auto h-auto" src="/assets/img/logos/VF.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full md:hidden" style={{
      position: "fixed",
      bottom:0,
      top:0,
      left:0, 
      right:0, 
      width: "100%",
      height: "100dvh",
    }}>
        <div className="w-full h-full flex flex-col items-center bg-lynx-background">
          <div className='w-full flex flex-row gap-2 p-2 items-center h-24 bg-white'>
            <img className='h-full pr-4 cursor-pointer' src="./assets/img/logos/lynx.png" onClick={() => {
              navigate("/menu")
            }}/>
            {
              /*!cartoOnly &&
              currentPage===Pages.taguage &&
              <button className={"hover:opacity-90 bg-lynx-info text-xl rounded h-10 font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                  navigateTo("https://safeplace.teepee.fr/#/viewData/CD986F39/34425AE7/Appariement/MiseAJourDesFormulaires?context=022383A9-DC04-4C50-96BA-4654F989D9FC");
                }}>
                  Nouvelle demande
              </button>*/
            }
            {
              <div className='flex-1 flex flex-col gap-2 items-start bg-lynx-background'>
                <div className='flex flex-row w-full gap-2 border-b-2 p-2 '>
                  <Icon icon="search" className='text-gray-500'/>
                  <input
                    value={textFilter}
                    onChange={(e) => {
                      setTextFilter(e.target.value)
                    }}
                    className="text-xl appearance-none bg-transparent border-none w-full placeholder-gray-500 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    placeholder="Rechercher..."
                  />
                </div>
              </div>
            }
            {
              !cartoOnly &&
              currentPage===Pages.taguage &&
              <button className={"hover:opacity-90 bg-lynx-info text-white h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                navigateTo("https://safeplace.teepee.fr/ios/#/viewData/CD986F39/34425AE7/Appariement/MiseAJourDesFormulaires?context=022383A9-DC04-4C50-96BA-4654F989D9FC");
              }}>
                <Icon className="" icon="plus"/>
              </button>
            }
            <div className='flex flex-row justify-end items-center gap-4 pr-2'>
              {
                currentPage===Pages.carto &&
                <button className={`${isPresentationMode ? "bg-blue-100" : "bg-lynx-background"} hover:bg-yellow-200  h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4`} onClick={async () => {
                  setPresentationMode(!isPresentationMode)
                  if (!isPresentationMode)
                  {
                    setSearchParams({ page: Pages.carto});
                  }
                }}>
                  <Icon className="" icon="presentation"/>
                </button>
              }
              <button className={"hover:opacity-90 bg-red-500 text-white h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center pl-4 pr-4"} onClick={async () => {
                axios.get("/api/v1/auth/logout")
                window.location = "/" as any
              }}>
                <Icon className="" icon="log-out"/>
              </button>
            </div>
          </div>
          <div className='w-full flex-1 overflow-hidden bg-lynx-background'>
            <div className='w-full h-full overflow-hidden flex flex-col gap-2 items-center'>
              
              {!cartoOnly && currentPage === Pages.taguage && <Taguage textFilter={textFilter} setTextFilter={setTextFilter} />}
              {!cartoOnly && currentPage === Pages.pacom && <Pacom textFilter={textFilter} setTextFilter={setTextFilter}/>}
              {currentPage === Pages.carto && <Carto />}
              {!cartoOnly && currentPage === Pages.prospection && <Prospection textFilter={textFilter} setTextFilter={setTextFilter}/>}
              {!cartoOnly && currentPage === Pages.settings && <Settings />}
            </div>
          </div>
          <div className='w-full flex flex-row gap-2 items-center overflow-hidden bg-white h-28 pl-4 pr-4' >
            {
              !isPresentationMode &&
              <>
                {
                  !cartoOnly &&
                  <>
                    <ButtonMenu text="Taguage" isSelected={currentPage===Pages.taguage} icon="tag.svg" onClick={() => {setSearchParams({ page: Pages.taguage});}}/>
                    <ButtonMenu text="Prospection"  isSelected={currentPage===Pages.prospection}  icon="prospection.svg" onClick={() => {setSearchParams({ page: Pages.prospection});}}/>
                    <ButtonMenu text="PACOM" isSelected={currentPage===Pages.pacom}  icon="pacom.svg" onClick={() => {setSearchParams({ page: Pages.pacom});}}/>
                  </>
                }
              </>
            }
            <ButtonMenu text="Cartographie"  isSelected={currentPage===Pages.carto}  icon="map.svg" onClick={() => {setSearchParams({ page: Pages.carto});}}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lynx;