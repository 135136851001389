/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

/* eslint-disable @typescript-eslint/no-explicit-any */

interface RealEastateAsset {
  uuid: string;
  nature: string;
  surface: number;
  surfaceCategory: string;
  commercialName: string;
  address: string;
  postcode: string;
  city: string;
  facilityManagerName: string;
  contractType: string;
  direction: string;
  lat: number;
  lon: number;
  buildings: Array<string>;
  metadata: Record<string,string>;
  searchFeatures?: any;
}

interface Config {
  colors: Record<string, string>;
}

interface Feature {
  address: string;
  center: Array<number>;
  id: string;
  place_name: string;
}


enum Criticity {
  INFO = "Info",
  IMPORTANT = "Important",
  WARNING = "Warning",
  ERROR = "Erreur"
}

interface Log {
  processUUID: string;
  date: string;
  criticity: Criticity
  message: string;
}

enum JobState {
  Ongoing = "Ongoing",
  Cancelled = "Cancelled",
  Failed = "Failed",
  Success = "Success",
  Suspended = "Suspended",
  Unknown = "Unknown",
}

interface JobStatus {
  jobUUID: string;
  label: string;
  state: JobState;
  start: string;
  end: string;
}

interface Asset {
  uuid: string;
  adress: string;
  streetNumber: string;
  street: string;
  postCode: string;
  city: string;
  lat: string;
  lon: string;
  buildings: Array<string>;
  entrepriseVF: string;
  pole: string;
  typeDActif: string;
  typeDeClient: string;
  typeGestionnaireActif: string;
  typeDePrestation: string;
  surfaceTotale: string;
  StatutDeLActif: string;
  BDDataClient1: string;
  BDDataProprio: string;
  BDDataAssetMgr: string;
  BDDataPM: string;
  BDDataNomComSite: string;
  pacom?: string;
  rawData: unknown;
  propertyInspector: Record<string, Record<string, string>>;
  BDDataClients: Array<string>;
  BDDataEntVFs: Array<string>;
}

interface AssetCarto {
  assetID: string;
  latitude: string;
  longitude: string;
}

interface AssetColor {
  name: string;
  color: string;
}

interface AssetBuilding {
  assetID: string;
  buildingID: string;
}

interface Direction {
  id: number;
  entreprise: string;
  direction: string;
}

enum ActifTypesCarto {
  "Gagne"="Actif en portefeuille VINCI Facilities",
  "Perdu"="Actif perdu",
  "HorsVinciEnProspection"="Actif hors portefeuille VINCI Facilities en prospection",
  "HorsVinciLibre"="Actif hors portefeuille VINCI Facilities"
}

enum MonitoringCategories {
  User="User",
  Business="Business",
  TeePee="TeePee",
  PowerBI="PowerBI"
}

enum MonitoringEvent {
  Login="Login",
  Access="Access",
  Logout="Logout",
  Initialised="Initialised",
  Update="Update",
  LoadTaguage="Load Appariements",
  LoadProspection="Load Prospection",
  LoadPacom="Load Pacom",
  LoadCarto="Load Carto",
  LoginFailed="Login Failed",
  UpdateRequest="Update Request",
  FullSynchroStart="Full Synchro Start",
  FullSynchroDone="Full Synchro Done",
  FullSynchroError="Full Synchro Error",
  GetContract="Get Contract"
}


interface LynxDocument {
  uuid: string;
  asset: string;
  name: string;
  date: string
  uploader: string;
  creation: string;
}

enum S3Roles {
  Supprimer='Supprimer',
  Importer='Importer',
  Visualiser='Visualiser'
}

export {
  Asset,
  AssetBuilding,
  AssetCarto,
  AssetColor,
  Direction,
  LynxDocument,
  RealEastateAsset,
  Config,
  Feature,
  Log,
  Criticity,
  JobStatus,
  JobState,
  ActifTypesCarto,
  MonitoringCategories,
  MonitoringEvent,
  S3Roles
}

