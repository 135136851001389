/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'mapbox-gl/dist/mapbox-gl.css';

import turfDistance from '@turf/distance';
import * as turf from '@turf/turf';
import React from 'react';
import ReactMapGL, { Marker } from "react-map-gl";
import useSupercluster from "use-supercluster";

import { ActifTypesCarto, Asset } from '../../../shared/interfaces';
import { useApplicationSettingsContext } from '../../contexts/application-settings-provider';
import kdt from './../../../shared/kd-tree';
import AssetCard from './asset-card';

const minDistanceToMatchBuilding = 45;
const featureIdToAssetID = {};

interface FeatureCenter {
  lat: number;
  lon: number;
  feature: any;
}

const getFeatureCenter = (feature): FeatureCenter => {
  let minLat = 0;
  let maxLat = 0;
  let minLon = 0;
  let maxLon = 0;
  let index = 0;
  if (feature.geometry.type === "MultiPolygon")
  {
    if (feature.geometry.coordinates)
    {
      feature.geometry.coordinates.forEach(multipoly => {
        multipoly.forEach(coordSet => {
          coordSet.forEach(coord => {
            if (index === 0)
            {
              minLat = coord[0];
              maxLat = coord[0];
              minLon = coord[1];
              maxLon = coord[1];
            } else {
              minLat = minLat < coord[0] ? minLat : coord[0];
              maxLat = maxLat > coord[0] ? maxLat : coord[0];
              minLon = minLon < coord[1] ? minLon : coord[1];
              maxLon = maxLon > coord[1] ? maxLon : coord[1];
            }
            index++;
          });
        })
      });
    }
  } else {
    if (feature.geometry.coordinates)
    {
      feature.geometry.coordinates.forEach(coordSet => {
        coordSet.forEach(coord => {
          if (index === 0)
          {
            minLat = coord[0];
            maxLat = coord[0];
            minLon = coord[1];
            maxLon = coord[1];
          } else {
            minLat = minLat < coord[0] ? minLat : coord[0];
            maxLat = maxLat > coord[0] ? maxLat : coord[0];
            minLon = minLon < coord[1] ? minLon : coord[1];
            maxLon = maxLon > coord[1] ? maxLon : coord[1];
          }
          index++;
        });
      });
    }
  }
  return {
    lon: minLat + ((maxLat - minLat) * 0.5),
    lat: minLon + ((maxLon - minLon) * 0.5),
    feature: feature
  };
};

const featuresCenterDistance = (a: FeatureCenter, b: FeatureCenter) => {
  return Math.pow(a.lat - b.lat, 2) +  Math.pow(a.lon - b.lon, 2);
}

/*const checkIfPositionInViewport = (bounds, lat, lng) => {
  return (lat >= bounds._sw.lat && lat <= bounds._ne.lat && lng >= bounds._sw.lng && lng <= bounds._ne.lng);
}*/

let currentFeatures: Array<any> = [];
const kdItems: Record<number, FeatureCenter> = {};
//let kdTree: any = undefined;
//let focused = undefined;

const createBuildingLayer = (sourceName: string, color: string) =>{
  return {
    id: `${sourceName}_3d`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'fill-extrusion',
    'minzoom': 15,
    'paint': {
      'fill-extrusion-color': color,
      // Use an 'interpolate' expression to
      // add a smooth transition effect to
      // the buildings as the user zooms in.
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'height']
      ],
      'fill-extrusion-base': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'min_height']
      ],
      'fill-extrusion-opacity': 1
    }
  };
}

const createHorsVinciLibreLayer = (sourceName: string) =>{
  return {
    id: `${sourceName}_2d`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'fill',
    'minzoom': 15,
    'paint': {
      "fill-color": "#394054"
    }
  };
}

const createActifProspectionLayer = (sourceName: string, color: string) =>{
  return {
    id: `${sourceName}_2d`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'fill',
    'minzoom': 15,
    'paint': {
      "fill-color": color,
    }
  };
}

const createActifPerduLayer = (sourceName: string) =>{
  return {
    id: `${sourceName}_2d`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'fill',
    'minzoom': 15,
    'paint': {
      "fill-color": "#394054",
    }
  };
}

const createActifPerduOutlineLayer = (sourceName: string, color: string) =>{
  return {
    id: `${sourceName}_lines`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'line',
    'minzoom': 15,
    'paint': {
      "line-color": color,
      "line-width": 4
    }
  };
}

const createActifPerduSymbolLayer = (sourceName: string) =>{
  return {
    id: `${sourceName}_symbols`,
    source: sourceName,
    'filter': ['==', 'extrude', 'true'],
    'type': 'symbol',
    'minzoom': 16,
    'layout': {
      "icon-image": "lost",
      "icon-allow-overlap": true,
      "icon-size": [
          'interpolate',
          // Set the exponential rate of change to 1.5
          ['exponential', -1.5],
          ['zoom'],
          // When zoom is 10, icon will be 60% size.
          10,
          0.2,
          // When zoom is 15, icon will be 10% size.
          15,
          0.05
      ],
  }
  };
}

const MapComponent: React.FC<{
  assets: Array<Asset>
  colors: Record<string, string>;
  groupes: Record<string, string>;
  onSelect: (uuid: string) => void;
  viewport: {
    longitude: number
    latitude: number
    zoom: number
  }
  setViewport: (v: any) => void
}> = ({
  assets,
  colors,
  groupes,
  onSelect,
  viewport,
  setViewport
}) => {
  /*const [viewport, setViewport] = React.useState({
    longitude: 2.3368161614,
    latitude: 48.8636451931,
    zoom: 12
  });*/
  const {isPresentationMode} = useApplicationSettingsContext();
  const [markers, setMarkers] = React.useState<Array<any>>([]);
  const mapRef = React.useRef();
  const [kdTree, setkdTree] = React.useState<any>(undefined);
  const [, updateState] = React.useState<unknown>();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  //const [hiddenGroupes, setHiddenGroupes] = React.useState<Array<string>>([]);
  //const [visibleAssets, setVisibleAssets] = React.useState<Array<RealEastateAsset>>([]);
  //const [assetSortedByDistance, setAssetSortedByDistance] = React.useState<Array<Asset>>([]);
  const [selectedAsset, setSelectedAsset] = React.useState<string>(undefined);

  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  /*React.useEffect(() => {
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    const filteredAssets = assets.filter(asset => !hiddenGroupes.includes(asset.groupe));
    setVisibleAssets(filteredAssets);
    hiddenGroupes.forEach(groupe => {
      if (map.getSource(`groupe_${groupe}`))
      {
        map.getSource(`groupe_${groupe}`).setData({
          type: "FeatureCollection",
          features: [],
        });
      }
    })
  }, [assets, hiddenGroupes, mapRef]);*/

  React.useEffect(() => {
    if (!mapRef.current) {
      return;
    };
    const points = assets.map((asset) => ({
      type: "Feature",
      properties: {
        cluster: false,
        assetUUID: asset.uuid,
        color: groupes[asset.uuid] ? colors[groupes[asset.uuid]] : "#ff0000"
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(asset.lon),
          parseFloat(asset.lat)
        ]
      }
    }));
    //console.log("setMarkers", points)
    setMarkers(points);
  }, [assets, colors, groupes]);

  const bounds = mapRef.current && (mapRef.current as any).getMap
    ? (mapRef.current as any).getMap()
        .getBounds()
        .toArray()
        .flat()
    : null;

  const { clusters, supercluster } = useSupercluster({
    points: markers,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 25 }
  });

  const onLoad = (e) => {
    const layers = e.target.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;

    /*e.target.addSource("currentBuildings", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    e.target.addLayer(
      extrudedBuildingLayerSetup,
      labelLayerId
    );*/
  }

  const onMouseMove = (e) => {
    /*const i = 0;
    const mousePos = e.lngLat.wrap();

    let retainedFeature = undefined;
    if (kdTree)
    {
      const nearest = kdTree.nearest({ lat: mousePos.lat, lon: mousePos.lng }, 4);
      retainedFeature = nearest.reverse()[0][0].feature;
    }
    if (focused !== retainedFeature)
    {
      focused = retainedFeature
    }

    let retainedFeatures = [];
    if (retainedFeature)
    {
      if (retainedFeature.id)
      {
        retainedFeatures = currentFeatures.filter(feat => feat.id === retainedFeature.id);

      } else {
        retainedFeatures = [retainedFeature];
      }
    }

    e.target.getSource("currentBuildings").setData({
      type: "FeatureCollection",
      features: retainedFeatures,
    });*/
    onMouseOver(e)
  }

  const [displayPopup, setDisplayPopup] = React.useState<{
    asset: string;
    coordinates: any;
    point: any;
  }>(undefined)

  const onMouseUp = (e) => {
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    const features = map.queryRenderedFeatures(e.point);
    let assetUUID = ""
    //let coordinates = undefined;
    features.forEach(feat => {
      if (featureIdToAssetID[feat.id])
      {
        assetUUID = featureIdToAssetID[feat.id];
        const coord = [feat.geometry.coordinates[0][0][0], feat.geometry.coordinates[0][0][1]];
        for(let i = 1; i<feat.geometry.coordinates[0].length; i++)
        {
          coord[0] += feat.geometry.coordinates[0][i][0]
          coord[1] += feat.geometry.coordinates[0][i][1]
        }
        const ratio = 1 / feat.geometry.coordinates[0].length;
        coord[0] *= ratio;
        coord[1] *= ratio;
        //coordinates = coord;
      }
    })
    if (assetUUID)
    {
      
      onSelect(assetUUID)
    }
  }

  const onMouseOver = (e) => {
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    const features = map.queryRenderedFeatures(e.point);
    let assetUUID = ""
    let coordinates = undefined;
    features.forEach(feat => {
      if (featureIdToAssetID[feat.id])
      {
        assetUUID = featureIdToAssetID[feat.id];
        const coord = [feat.geometry.coordinates[0][0][0], feat.geometry.coordinates[0][0][1]];
        for(let i = 1; i<feat.geometry.coordinates[0].length; i++)
        {
          coord[0] += feat.geometry.coordinates[0][i][0]
          coord[1] += feat.geometry.coordinates[0][i][1]
        }
        const ratio = 1 / feat.geometry.coordinates[0].length;
        coord[0] *= ratio;
        coord[1] *= ratio;
        coordinates = coord;
      }
    })
    if (assetUUID)
    {
      console.log("assetUUID", assetUUID)
      
      setDisplayPopup({
        asset: assetUUID,
        coordinates: coordinates,
        point: map.project(coordinates)
      });
    } else {
      setDisplayPopup(undefined);
    }
  }

  const updateCurrentFeature = (map) => {
    if (kdTree === undefined)
    {
      currentFeatures = map.querySourceFeatures("composite", { sourceLayer: ['building'] });
      if (currentFeatures.length > 0 && currentFeatures[0].id)
      {
        currentFeatures.forEach(feature => {
          kdItems[feature.id] = getFeatureCenter(feature);
        });
        /*kdItems = currentFeatures.map(feature => {
          return getFeatureCenter(feature);
        });*/
        setkdTree(kdt(Object.values(kdItems), featuresCenterDistance, ['lat', 'lon']))
      }
    } else {
      let previousBuildingIdList = currentFeatures.filter(feature => feature.id).map(feature => feature.id);
      previousBuildingIdList = [...new Set(previousBuildingIdList)];

      currentFeatures = map.querySourceFeatures("composite", { sourceLayer: ['building'] });
      if (currentFeatures.length > 0 && currentFeatures[0].id)
      {
        let currentBuildingIdList = currentFeatures.filter(feature => feature.id).map(feature => feature.id);
        currentBuildingIdList = [...new Set(currentBuildingIdList)];

        const added = currentBuildingIdList.filter(cid => !previousBuildingIdList.includes(cid));
        const removed = previousBuildingIdList.filter(cid => !currentBuildingIdList.includes(cid));

        if ((added.length === 0) && (removed.length === 0))
        {
          return;
        }

        const removedPoints = removed.map(rid => kdItems[rid]); //kdItems.filter(point => removed.includes(point.feature.id));
        const newPoints = currentFeatures.filter(feature => added.includes(feature.id)).map(feature => {
          return getFeatureCenter(feature);
        });
        removedPoints.filter(point => point.lat && point.lon).forEach(point => kdTree.remove(point));
        newPoints.forEach(point => kdTree.insert(point));
        currentFeatures.filter(feature => added.includes(feature.id)).forEach(feature => {
          if (!kdItems[feature.id])
          {
            kdItems[feature.id] = getFeatureCenter(feature);
          }
        });
        currentFeatures.filter(feature => removed.includes(feature.id)).forEach(feature => {
          if (kdItems[feature.id])
          {
            kdItems[feature.id] = undefined;
          }
        });
        forceUpdate();
      }
    }
  }

  const onSourceData = (e) => {
    const id = e.sourceId as string;
    if (!id.startsWith("groupe"))
    {
      updateCurrentFeature(e.target);
    }
  };

  const onMoveEnd = (e) => {
    updateCurrentFeature(e.target);
  }

  const updateVisibility = (clusters, assets, kdTree, mapRef) => {
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    if (!assets) {
      return;
    }
    const singleLocation = clusters.filter(cluster => cluster.properties.cluster === false);

    const locatedAssets: Array<Asset> = singleLocation.map(loc => assets.find(a => a.uuid === loc.properties.assetUUID));

    //let edited = false;
    locatedAssets.filter(asset => asset && asset.buildings.length === 0).forEach(asset => {
      if (!asset)
      {
        return;
      }
      let retainedFeature = undefined;
      if (kdTree)
      {
        const nearest = kdTree.nearest({ lat: asset.lat, lon: asset.lon }, 4);
        retainedFeature = nearest.reverse()[0][0].feature;
        if(retainedFeature)
        {
          const center = getFeatureCenter(retainedFeature);
          const from = turf.point([parseFloat(asset.lon), parseFloat(asset.lat)]);
          const to = turf.point([center.lon, center.lat]);
          const options = {units: 'meters'} as any;
          const distance = turfDistance(from, to, options);
          if (distance > minDistanceToMatchBuilding)
          {
            retainedFeature = undefined;
          }
        }
      }
      /*if (retainedFeature && retainedFeature.id) {
        asset.buildings = [retainedFeature.id];
        edited = true;
      }*/
    });
    /*if (edited) {
      saveAssets(assets)
    }*/
    const visibleFeaturesPerGroupeAndStatus: Record<string,Record<string,Array<any>>> = {};
    locatedAssets.forEach(asset => {
      if (asset && asset.buildings.length) {
        asset.buildings.forEach(id => {
          const found = currentFeatures.filter(feat => feat.id === id);
          if (groupes[asset.uuid]) {
            if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]])
            {
              visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]] = {};
            }
            if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif])
            {
              visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [];
            }
            visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [
              ...visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif],
              ...found
            ]
          }
          //visibleFeatures = [...visibleFeatures, ...found];
        })
      } else {
        let retainedFeature = undefined;
        if (kdTree)
        {
          if (!asset)
          {
            return;
          }
          const nearest = kdTree.nearest({ lat: asset.lat, lon: asset.lon }, 4);
          retainedFeature = nearest.reverse()[0][0].feature;
          if(retainedFeature)
          {
            const center = getFeatureCenter(retainedFeature);
            const from = turf.point([parseFloat(asset.lon), parseFloat(asset.lat)]);
            const to = turf.point([center.lon, center.lat]);
            const options = {units: 'meters'} as any;
            const distance = turfDistance(from, to, options);
            if (distance > minDistanceToMatchBuilding)
            {
              retainedFeature = undefined;
            }
          }
        }
        if (retainedFeature)
        {
          if (retainedFeature.id)
          {
            const found = currentFeatures.filter(feat => feat.id === retainedFeature.id);
            found.forEach(feat => featureIdToAssetID[feat.id] = asset.uuid);
            if (groupes[asset.uuid]) {
              if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]])
              {
                visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]] = {};
              }
              if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif])
              {
                visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [];
              }
              visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [
                ...visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif],
                ...found
              ]
            }
            //visibleFeatures = [...visibleFeatures, ...found];  
          } else {
            //visibleFeatures = [...visibleFeatures, retainedFeature];
            
            console.log("Possible ?")
            if (groupes[asset.uuid]) {
              if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]])
              {
                visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]] = {};
              }
              if (!visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif])
              {
                visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [];
              }
              visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif] = [
                ...visibleFeaturesPerGroupeAndStatus[groupes[asset.uuid]][asset.StatutDeLActif],
                retainedFeature
              ]
            }
          }
        }
      }
    });
    Object.keys(visibleFeaturesPerGroupeAndStatus).forEach(groupe => {
      Object.keys(visibleFeaturesPerGroupeAndStatus[groupe]).forEach(status => {
        if (map.getSource(`groupe_${status}_${groupe}`))
        {
          map.getSource(`groupe_${status}_${groupe}`).setData({
            type: "FeatureCollection",
            features: visibleFeaturesPerGroupeAndStatus[groupe][status],
          });
        }
      });
    })
    /*hiddenGroupes.forEach(groupe => {
      if (map.getSource(`groupe_${groupe}`))
      {
        map.getSource(`groupe_${groupe}`).setData({
          type: "FeatureCollection",
          features: [],
        });
      }
    })*/
  }

  React.useEffect(() => {
    updateVisibility(clusters, assets, kdTree, mapRef);
  }, [clusters, assets, kdTree, mapRef, groupes]);

  React.useEffect(() => {
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    if (!assets) {
      return;
    }
    
    /*const bounds = map.getBounds();
    
    const inside = [];
    const outside = [];
    visibleAssets.forEach(asset => {
      if (checkIfPositionInViewport(bounds, asset.lat, asset.lon)) {
        inside.push(asset);
      } else {
        outside.push(asset);
      }
    });
    console.log(inside)*/

    /*let assetDistanceArray: Array<{
      asset: Asset,
      distance: number
    }> = [];

    assets.forEach(asset => {
      const from = turf.point([parseFloat(asset.lon), parseFloat(asset.lat)]);
      const to = turf.point([viewport.longitude, viewport.latitude]);
      const options = {units: 'meters'} as any;
      const distance = turfDistance(from, to, options);
      assetDistanceArray.push({
        asset: asset,
        distance: distance
      })
    });*/

    /*assetDistanceArray = assetDistanceArray.sort((a,b) => a.distance - b.distance);
    setAssetSortedByDistance(assetDistanceArray.map(item => item.asset));*/
    
  }, [assets, viewport])

  React.useEffect(() => {
    if (!assets) {
      return;
    }
    if (!(mapRef.current && (mapRef.current as any).getMap)) {
      return;
    }
    const map = (mapRef.current as any).getMap();
    try {
      map.getStyle()
    } catch {
      forceUpdate();
      return;
    }

    if (!imageLoaded) {
      map.loadImage(
        '/assets/img/icons/P.png',
        (error, image) => {
          if (error) {
            console.log(error)
          };
          
          // Add the image to the map style.
          map.addImage('lost', image);
          setImageLoaded(true);
        }
      );
      return;
    }

    //console.log("sources", map.getStyle().sources)
    let assetGroupes = assets.map(asset => groupes[asset.uuid]);
    let assetStatus = assets.map(asset => asset.StatutDeLActif);

    assetGroupes = [...new Set(assetGroupes)];
    assetStatus = [...new Set(assetStatus)];
    
    const layers = map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;
    
    // cleaning all layers
    let sources = [];
    layers.forEach(layer => {
      const layerName = layer.id as string;
      if (layerName.startsWith("groupe_"))
      {
        const split = layerName.split("_")
        const sourceName = `${split[0]}_${split[1]}_${split[2]}`;
        sources.push(sourceName)
        map.removeLayer(layerName);
      }
    })
    sources = [... new Set(sources)];
    sources.forEach(sourceName => {
      if (map.getSource(sourceName))
      {
        map.removeSource(sourceName);
      }
    });

    assetGroupes.forEach(groupe => {
      assetStatus.forEach(status => {
        if (!map.getSource(`groupe_${status}_${groupe}`))
        {
          map.addSource(`groupe_${status}_${groupe}`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });

          switch (status) {
            case ActifTypesCarto.Gagne: 
            {
              map.addLayer(
                createBuildingLayer(`groupe_${status}_${groupe}`, colors[groupe] ? colors[groupe] : "#fff"),
                labelLayerId
              );
              break
            }

            case ActifTypesCarto.HorsVinciLibre: 
            {
              map.addLayer(
                createHorsVinciLibreLayer(`groupe_${status}_${groupe}`),
                labelLayerId
              );
              break
            }

            case ActifTypesCarto.Perdu: 
            {
              map.addLayer(
                createActifPerduLayer(`groupe_${status}_${groupe}`),
                labelLayerId
              );
              map.addLayer(
                createActifPerduOutlineLayer(`groupe_${status}_${groupe}`, colors[groupe] ? colors[groupe] : "#fff"),
                labelLayerId
              );
              map.addLayer(
                createActifPerduSymbolLayer(`groupe_${status}_${groupe}`),
                labelLayerId
              );
              break
            }

            case ActifTypesCarto.HorsVinciEnProspection: {
              map.addLayer(
                createActifProspectionLayer(`groupe_${status}_${groupe}`, colors[groupe] ? colors[groupe] : "#fff"),
                labelLayerId
              );
              break;
            }
        
            default:
            {
              map.addLayer(
                createBuildingLayer(`groupe_${status}_${groupe}`, colors[groupe] ? colors[groupe] : "#fff"),
                labelLayerId
              );
            }
          }
          
          
        } else {
          console.log("Layer error !")
        }
      })
      
    })
  }, [mapRef, assets, forceUpdate, colors, groupes, imageLoaded]);

  const travelTo = (lon, lat) => {
    setkdTree(undefined);
    setViewport({
      ...viewport,
      latitude: lat,
      longitude: lon,
      zoom: 18,
      width: '100vw',
      height: '100vh',
    });
  }

  React.useEffect(() => {
    if (!selectedAsset) {
      return;
    }
    const found = assets.find(a => a.uuid === selectedAsset)
    travelTo(found?.lon, found?.lat);
  }, [selectedAsset]);

  const assetMap = React.useMemo(() => {
    const result = {};
    assets.forEach(asset => result[asset.uuid] = asset)
    return result;
  }, [assets])

  /*const [mousePosition, setMousePosition] = React.useState({
    left: 0,
    top: 0
  })*/
  const ref = React.useRef(null);
  /*const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);*/

  /*React.useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
    setHeight(ref.current.clientHeight);
  }, []);*/

  //function handleMouseMove(ev) { console.log(ev.clientX, ev.clientY, width, height); setMousePosition({left: ev.clientX, top: ev.clientY}); }

  /*const popupOffsetWidth = displayPopup ? Math.floor(displayPopup.point.y) > (width * 0.5) ? -50 : 50 : 0;
  const popupOffsetHeight = displayPopup ? Math.floor(displayPopup.point.x) > (height * 0.5) ? -50 : 50 : 0;*/
  return (
    <div className="w-full h-full flex flex-row gap-2 md:p-2 relative" ref={ref}>
      {
        displayPopup &&
        <div className='absolute z-10 pointer-events-none' style={{
          top: `${Math.floor(displayPopup.point.y)}px`,
          left: `${Math.floor(displayPopup.point.x)}px`
        }}>
          <AssetCard asset={assetMap[displayPopup.asset]} close={() => { setDisplayPopup(undefined)}}/>
        </div>
      }
      <div className="flex-1 h-full flex flex-col gap-2">
        <div ref={mapRef} className="flex-1 h-full">
          <ReactMapGL
            {...viewport}
            onMove={evt => {
              setViewport(evt.viewState);
              if (!(mapRef.current && (mapRef.current as any).getMap)) {
                return;
              }
              const map = (mapRef.current as any).getMap();
              if (displayPopup)
              {
                //onSelect(isplayPopup.asset)
                setDisplayPopup({
                  asset: displayPopup.asset,
                  coordinates: displayPopup.coordinates,
                  point: map.project(displayPopup.coordinates)
                });
              }
            }}
            maxZoom={20}
            mapboxAccessToken={process.env.MAPBOX_TOKEN}
            ref={mapRef}
            mapStyle={"mapbox://styles/mapbox/streets-v11"}
            interactive={true}
            style={{
              width: "100%",
              height: "100%",
            }}
            onLoad={onLoad}
            onSourceData={onSourceData}
            onMouseMove={onMouseMove}
            onMoveEnd={onMoveEnd}
            onMouseUp={onMouseUp}
            trackResize={true}
            onZoom={() => {
              if (!(mapRef.current && (mapRef.current as any).getMap)) {
                return;
              }
              const map = (mapRef.current as any).getMap();
              if (displayPopup)
              {
                setDisplayPopup({
                  asset: displayPopup.asset,
                  coordinates: displayPopup.coordinates,
                  point: map.project(displayPopup.coordinates)
                });
              }
            }}
          >
            {
              clusters.map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                  cluster: isCluster,
                  point_count: pointCount
                } = cluster.properties;

                if (isCluster) {
                  return (
                    <Marker
                      key={`cluster-${cluster.id}`}
                      latitude={latitude}
                      longitude={longitude}
                    >
                      <div
                        className="cluster-marker"
                        style={{
                          width: `${20 + (pointCount / markers.length) * 40}px`,
                          height: `${20 + (pointCount / markers.length) * 40}px`,
                          color: "#fff",
                          background: "#0C2F8D",
                          borderRadius: "50%",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "14px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20
                          );
                          setkdTree(undefined);
                          setViewport({
                            ...viewport,
                            latitude,
                            longitude,
                            zoom: expansionZoom,
                            width: '100%',
                            height: '100%',
                            /*transitionInterpolator: new FlyToInterpolator({
                              speed: 2
                            }),
                            transitionDuration: "auto"*/
                          });
                        }}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }

                //console.log("cluster", cluster.properties, groupes[cluster.properties.assetUUID], colors[groupes[cluster.properties.assetUUID]])
                let color = colors[groupes[cluster.properties.assetUUID]];
                if (assetMap[cluster.properties.assetUUID])
                {
                  if(assetMap[cluster.properties.assetUUID].StatutDeLActif === ActifTypesCarto.Perdu) {
                    color = "#394054"
                  }

                  if(assetMap[cluster.properties.assetUUID].StatutDeLActif === ActifTypesCarto.HorsVinciLibre) {
                    color = "#394054"
                  }
                }
                return (
                  <Marker
                    key={`asset-${cluster.properties.assetUUID}-${groupes[cluster.properties.assetUUID]}-${isPresentationMode ? "a" : "b"}`}
                    latitude={latitude}
                    longitude={longitude}
                    scale={0.8}
                    color={color}
                    onClick={() => {
                      setSelectedAsset(cluster.properties.assetUUID)
                      onSelect(cluster.properties.assetUUID)
                    }}
                    style={{cursor: "pointer"}}
                  >
                  </Marker>
                );
              })
            }
          </ReactMapGL>
        </div>
      </div>
      
    </div>
  );
}

export default MapComponent;

