/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

import { Button, Intent } from '@blueprintjs/core';
import axios from 'axios';
import { saveAs } from 'file-saver';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DisplayConfig, EnumConfig } from '../../shared/directus';
import { getCookie } from '../../shared/helpers';
import JobProcess from '../components/job-process';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import ColorSettings from './settings/color-settings';
import Directions from './settings/directions';
import Logs from './settings/logs';


const Settings: React.FC = () => {
  const { token }= useApplicationSettingsContext();
  const [currentJob, setCurrentJob] = React.useState<string>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [editColors, setEditColors] = React.useState<boolean>(false);
  const [editDirections, setEditDirections] = React.useState<boolean>(false);
  const [displayMonitoringLogs, setDisplayMonitoringLogs] = React.useState<boolean>(false);

  const navigate = useNavigate()

  const onExportConfigurations = async () => {
    const { data: config } = await axios.get<Array<EnumConfig>>(`/api/directus/collection/${process.env.CONFIG_TABLE_NAME}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    const { data: enumConfig } = await axios.get<Array<EnumConfig>>(`/api/directus/collection/${process.env.AP_ENUMERATIONS}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    const { data: displayConfig } = await axios.get<Array<DisplayConfig>>(`/api/directus/collection/${process.env.AP_CONFIGURATION_AFFICHAGE}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exportData: any  = {};
    exportData[process.env.CONFIG_TABLE_NAME] = config;
    exportData[process.env.AP_ENUMERATIONS] = enumConfig;
    exportData[process.env.AP_CONFIGURATION_AFFICHAGE] = displayConfig;
    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' })
    saveAs(blob, `lynx.configurations`);
  }

  const onImportConfiguration = async (files: FileList | null) => {
    if (files && files.length === 1) {
      const file = files[0];
      const reader = new FileReader()

      reader.readAsText(file)
      reader.onload = async () => {
        const text = reader.result as string;
        const settings = JSON.parse(text);
        await axios.post(`/api/application/settings`, settings,{
          headers: {
            authorization: `Basic ${token??getCookie('lynx')}`
          }
        });
        navigate("/")
      }
    }
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }

  return (
    <div className="w-full h-full flex flex-col gap-2 p-2 items-center">
      <div className='w-full text-4xl text-center font-bold text-blue-900'>{`Configurations`}</div>
      <div className='w-full flex-1 overflow-hidden p-2 items-center'>
        {
          currentJob === undefined && !editColors && !editDirections && !displayMonitoringLogs &&
          <div className="w-full h-full flex flex-row gap-2 p-2 items-center justify-center">
            <div className="flex flex-col gap-2 items-center justify-center w-1/4">
              <Button className="w-full" text="Log de monitoring" intent={Intent.PRIMARY} onClick={() => { setDisplayMonitoringLogs(true) }}/>
              <Button className="w-full" text="Export des paramètres de l'application" intent={Intent.WARNING} onClick={onExportConfigurations}/>
              <Button className="w-full" text="Import des paramètres de l'application" intent={Intent.DANGER} onClick={() => { inputRef.current.click(); }}/>
              <Button className="w-full" text="Synchronisation TeePee complète" intent={Intent.WARNING} onClick={() => { setCurrentJob('synchronise-teepee') }}/>
              <Button className="w-full" text="Nettoyage de la base de donnée" intent={Intent.DANGER} onClick={() => { setCurrentJob('clean-database') }}/>
              <Button className="w-full" text="Calculs des latitudes/longitudes" intent={Intent.WARNING} onClick={() => { setCurrentJob('compute-lat-lon') }}/>
              <Button className="w-full" text="Editer les couleurs" intent={Intent.PRIMARY} onClick={() => { setEditColors(true) }}/>
              <Button className="w-full" text="Editer les directions" intent={Intent.PRIMARY} onClick={() => { setEditDirections(true) }}/>
              <Button className="w-full" text="Télécharger les contrats" intent={Intent.PRIMARY} onClick={async () => { 
                window.open("/api/contracts/download");
                /*const req = await axios.get(`/api/powerbi/contracts/download`, {
                  headers: {
                    authorization: `Basic ${token??getCookie('lynx')}`
                  }
                });
                const blob = new Blob([req.data], {
                  type: req.headers["content-type"],
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `contracts.xlsx`;
                link.click();*/
               }}/>
            </div>
          </div>
        }
        {
          !displayMonitoringLogs && currentJob !== undefined && !editColors && !editDirections && <JobProcess jobName={currentJob} onQuit={() => {setCurrentJob(undefined)}}/>
        }
        {
          editColors && !editDirections &&  !displayMonitoringLogs &&
          <ColorSettings />
        }
        {
          !editColors && editDirections && !displayMonitoringLogs &&
          <Directions />
        }
        {
          !editColors && !editDirections && displayMonitoringLogs &&
          <Logs />
        }
        <input
          type="file"
          className="hidden"
          name="attachments"
          onChange={event => {
            onImportConfiguration(event.target.files);
          }}
          ref={inputRef}
        />
      </div>
    </div>
  );
}

export default Settings;